<template>
  <div id="accountingContent" class="content">
    <h1>Buchhaltung</h1>
    <p>
      Flexible Modelle je nach Kundenwunsch. Wollen Sie die Belege selber
      erfassen und nur Unterstützung beim Jahresabschluss oder gleich die
      gesamte Buchhaltung auslagern? Wir finden eine Lösung, die für Sie passt.
    </p>
    <ul>
      <li>Buchhaltung führen</li>
      <li>Jahresabschluss</li>
      <li>Mehrwertsteuer inkl. Jahresabstimmung</li>
    </ul>
  </div>
</template>
