<template>
  <div id="trainingContent" class="content">
    <h1>Schulungen</h1>
    <p>
      Sie wollen wissen, wie es mit der Buchhaltung, den Steuern oder der MWST in Ihrem Unternehmen genau läuft?
      Ich biete individuelle Schulungen auf Sie abgestimmt.
    </p>
    <p>
      Sie arbeiten mit bexio, aber es läuft nicht so einfach wie in der Werbung angekündigt? <br />
      Mit individueller Schulung kann ich Ihnen Optimierungen zeigen.
    </p>
  </div>
</template>
