<template>
  <div id="taxContent" class="content">
    <h1>Steuern</h1>
    <p>Panta rhei… alles fliesst und das Leben bringt oft Unerwartetes.
      Spezielle Lebenssituationen wie Heirat / Trennung, Todesfall / Erbe, Kauf / Verkauf von Grundstücken, Renovationen etc. bringen neue Steuerherausforderungen.</p>
    <ul>
      <li>Komplettes Ausfüllen der Steuererklärung</li>
      <li>Online-Check in TaxMe</li>
    </ul>

    <ul>
      <li>Private</li>
      <li>Erbengemeinschaften</li>
      <li>Miteigentümergemeinschaften</li>
      <li>Einzelfirmen</li>
      <li>Gesellschaften (AG, GmbH, Vereine)</li>
    </ul>
  </div>
</template>
