<template>
  <div id="mainNavigation" class="nav">
    <img src="../assets/logo.png" alt="logo.png" class="logo"/>
    <ul class="menu">
      <li class="nav-item"><a href="#taxContent">Steuern</a></li>
      <li class="nav-item"><a href="#accountingContent">Buchhaltung</a></li>
      <li class="nav-item"><a href="#salaryContent">Löhne</a></li>
      <li class="nav-item"><a href="#trainingContent">Schulungen</a></li>
      <li class="nav-item"><a href="#contactContent">Kontakt</a></li>
    </ul>
  </div>
</template>

<script setup>

</script>

<style scoped>
.logo{
  height: 80px;
}

.nav {
  margin: 10px auto;
  align-items: center;
  overflow: hidden;
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
}

.nav ul {
  list-style-type: none;
  padding: 40px 0 0;
  /*overflow: hidden;*/
  display: flex;
  flex-wrap: wrap;
  /*justify-content: space-between;*/
}
.nav li {
  text-align: left;
  padding: 5px 10px;
}

.nav li a {
  height: 100%;
  color: #b2dad0;
  /*text-align: left;*/
  font-size: large;
  text-transform: uppercase;
  text-decoration: none;
}

@media screen and (max-width: 410px) {
  .nav {
    flex-direction: column;
  }
  .nav ul
  {
    /*flex-direction: column;*/
    justify-content: space-between;
    padding: 0;
  }

}

</style>