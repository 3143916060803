<template>
  <div class="container">
    <Navigation />
    <div class="header">
      <div id="headerPic">
      </div>
      <div id="slogan">
<!--        <div><img src="./assets/logo.png" alt="logo-tmosimann" /></div>-->
        Eine BRÜCKE zwischen <br/> Buchhaltungs-Handwerk und Alltagsleben
      </div>
    </div>
    <div id="content">
      <Tax />
      <Accounting />
      <Salary />
      <Training />
    </div>
    <Contact />
    <div class="footer"><LegalNotice /></div>
  </div>
</template>

<script>
import Accounting from "./components/AccountingContent.vue";
import Tax from "./components/TaxContent.vue";
import Salary from "./components/SalaryContent.vue";
import Training from "./components/TrainingContent.vue";
import Contact from "./components/ContactContent.vue";
import Navigation from "./components/MainNavigation.vue";
import LegalNotice from "./components/LegalNotice.vue";

export default {
  name: 'App',
  components: {
    Accounting: Accounting,
    Tax: Tax,
    Salary: Salary,
    Training: Training,
    Contact: Contact,
    Navigation: Navigation,
    LegalNotice: LegalNotice
  }
}
</script>

<style>
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #b0d7c4;
  margin: 0;
}

*, *:before, *:after {
  box-sizing: inherit;
}

#headerPic {
  background-image: url(../src/assets/bridge.jpg);
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  height: 100%;
}

#slogan {
  font-family: "Segoe Print", cursive;
  font-size: 4vw;
  line-height: 1.3;
  /*font-weight: bold;*/
  padding-bottom: 20px;
  color: #b2dad0;
  position: absolute;
  text-align: center;
  bottom: 0;
  width: 100%;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.6); /* Black background with 0.5 opacity */
}
#slogan img
{
  margin-top: 10px;
  height: 8vw;
}

.header {
  position: relative;
  height: 50vh;
}

#content {
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  max-width: 1400px;
  margin: 0 auto;
}

.content{
  max-width: 472px;
  margin: 0 auto;
  padding: 0 10px;
}

.container {
  display: flex; /* or inline-flex */
  flex-direction: column;
}

.footer{
  width: 100%;
  margin-top: 20px;
  padding: 50px 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
}

a{
  color: #b0d7c4;
}

@media screen and (min-width: 1000px) {
  #slogan{
    font-size: 40px;
  }
  #slogan img{
    height: 80px;
  }
}
</style>
