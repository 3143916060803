<template>
  <div id="salaryContent" class="content">
    <h1>Löhne</h1>
    <p>
      Spezialfälle wie Taggeld-Zahlungen führen häufig zu Fehlern bei Lohnabrechnungen.
      Ob als Backup für Fragen oder durch Übernahme der gesamten Lohnerfassung, ich helfe gerne.
    </p>

    <ul>
      <li>Lohnabrechnungen</li>
      <li>Lohnmeldungen Sozialversicherungen</li>
      <li>Lohnausweise</li>
    </ul>
  </div>
</template>
