<template>
  <div>
    <div ref="recaptcha"></div>
  </div>
</template>

<script>
export default {
  name: 'ReCaptcha',
  mounted() {
    window.grecaptcha.ready(() => {
      window.grecaptcha.render(this.$refs.recaptcha, {
        sitekey: '6Le0uKkkAAAAAN138RKJ9VMH4BHp4AoWGLayEtWK',
        callback: (token) => {
          this.$emit('captcha-success', token);
        },
      });
    });
  },
};
</script>