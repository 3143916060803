<template>
  <div id="contactContent" class="content">
    <h1 style="visibility: hidden">Kontakt</h1>
    <div style="display: flex; flex-direction: column; text-align: center"><img src="../assets/tania-mosimann.png" alt="tania-mosimann.jpg"/>
    <h3>
      Tania Mosimann-Maurer
    </h3>
    <p>Fachfrau im Finanz- und Rechnungswesen <br/> mit eidgenössischem Fachausweis </p>
    <table>
      <tr>
        <td><a href="https://veb.ch" target="_blank" rel="noopener noreferrer">
          <img src="../assets/veb_logo.svg" alt="veb_logo.svg" style="background: white; margin: 20px;" height="70px" />
        </a></td>
        <td><a href="https://veb.ch" target="_blank" rel="noopener noreferrer">
          Mitglied bei veb.ch
        </a></td>
      </tr>
      <tr>
        <td><a href="https://www.bexio.com/de-CH/" target="_blank" rel="noopener noreferrer">
          <img src="../assets/bexio-green-claim.png" alt="bexio-logo" height="70px" style="margin: 20px"/>
        </a></td>
        <td><a href="https://www.bexio.com/de-CH/" target="_blank" rel="noopener noreferrer">
          Zertifizierte bexio-Treuhänderin
        </a></td>
      </tr>
    </table>

    <p>
      TMosimann Treuhand <br/>
      Sägestrasse 30<br/>
      3415 Rüegsauschachen<br/>
    </p>
    <div v-if="phone === ''" style="display: flex; justify-content: center; flex-direction: column">
      <p>Für Telefon- und Mailkontakt bitte bestätigen:</p>
      <Recaptcha style="margin: auto" @captcha-success="onCaptchaSuccess" />
    </div>
    <div v-else>
      Tel: <a :href="`tel:${phone}`">{{phone}} </a><br/>
      Mail: <a :href="`mailto:${email}`">{{email}}</a>
    </div>
    </div>
  </div>
</template>

<script>
import Recaptcha from '@/components/ReCaptcha.vue';

export default {
  components: {
    Recaptcha,
  },
  data() {
    return {
      email: '',
      phone: ''
    }
  },
  methods: {
    onCaptchaSuccess(token) {
      let urlencoded = new URLSearchParams();
      urlencoded.append("token", token);
      fetch('verify-recaptcha.php',{
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: urlencoded,
        redirect: 'follow'
      }).then((response) => response.json()).then((data) => {
          console.log(data);
          if(data.success)
          {
            this.email = data.email;
            this.phone = data.phone;
          }
      } );
    },
  },
};
</script>

<style scoped>
a {
  color: #b0d7c4;
}

</style>